.scrollableprodcategory {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  padding: 20px;
  background-color: #f98e00;
  border-radius: 10px;
}
.allprodbackground {
  margin: 1px;
  background-color: rgb(0, 0, 0);
  border-radius: 6px;
}
