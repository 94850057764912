.firstsectionContainer {
  padding: 40px;
  background-color: rgb(255, 247, 230);
}
.firstSectionHeading {
  font-size: 55px;
  font-weight: 700;
  line-height: 60px;
  color: black;
}
.firstSectionsubHeading {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  max-width: 500px;
  color: rgb(94, 82, 65);
}
.firstSectionOrderNowButton {
  display: flex;
  flex-direction: row;
  padding: 16px;
  font-size: 24px;
  font-weight: 800;
  color: rgb(255 170 0);
  background-color: rgb(16 16 16);
  border-radius: 20px;
  border-width: 0px;
}
.deliverytext {
  font-size: 24px;
  font-weight: bold;
  color: rgb(61, 61, 61);
}
.firstsectionimage {
  width: 100%;
  border-radius: 20px;
}

/*second section*/
.landingsecondsection {
  padding: 40px;
  background-color: #ffe3cd;
}
.landingpagesectiontwoheading {
  font-size: 24px;
  font-weight: 500;
  color: rgb(255 170 0);
}
.landingpagesectiontwoviewmore {
  font-size: 24px;
  font-weight: 500;
  color: rgb(247, 177, 28);
  cursor: pointer;
}
.secondsextioncontent {
  height: fit-content !important;
}

/* phon css*/
@media only screen and (max-width: 600px) {
  .firstsectionContainer {
    padding: 16px;
    background-color: rgb(255, 247, 230);
  }
  .firstSectionHeading {
    font-size: 42px;
    font-weight: 700;
    line-height: 42px;
    color: black;
  }
  .firstSectionsubHeading {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    max-width: 500px;
    color: rgb(94, 82, 65);
  }
  .firstSectionOrderNowButton {
    padding: 12px;
    font-size: 18px;
    font-weight: 800;
    color: rgb(255 170 0);
    background-color: rgb(16 16 16);
    border-radius: 12px;
    border-width: 0px;
  }
  .deliverytext {
    font-size: 16px;
    font-weight: bold;
    color: rgb(61, 61, 61);
  }
  .firstsectionimage {
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
  }
  .mobilecssforordernow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  /*second section*/
  .landingsecondsection {
    padding: 16px;
  }
  .landingpagesectiontwoheading {
    align-self: center;
    font-size: 24px;
    font-weight: 800;
    color: rgb(255 170 0);
  }
  .landingpagesectiontwoviewmore {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    color: rgb(247, 177, 28);
    cursor: pointer;
    padding: 12px;
    text-decoration-line: none;
    border-radius: 12px;
    background-color: black;
  }
  .secondsextioncontent {
    height: fit-content !important;
  }
}

/*landing page section three*/
.landingsectionthree {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb888;
}

.foodcategorycard {
  padding: 19px;
  display: flex;
  justify-content: center;
  gap: 18px;
  align-items: center;
  flex-direction: column;
  font-size: 19px;
  color: rgb(255 170 0);
  background-color: white;
  border-radius: 18px;
  max-width: 150px;
}
.foodcategorycard:hover {
  box-shadow: 3px 4px #f19e9e;
  transition: all 0.1s ease-out;
}
.foodcategorycardimage {
  width: 150px;
}

.foodcateofytext {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
/* phon css*/
@media only screen and (max-width: 600px) {
  /*landing page section three*/
  .landingsectionthree {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffb888;
  }

  .foodcategorycard {
    padding: 11px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    font-size: 17px;
    color: rgb(255 170 0);
    background-color: white;
    border-radius: 18px;
    width: 100%;
    min-width: max-content;
  }
  .foodcategorycard:hover {
    box-shadow: 3px 4px #f19e9e;
    transition: all 0.1s ease-out;
  }
  .foodcategorycardimage {
    width: 100px;
  }

  .foodcateofytext {
    font-size: 30px;
    color: black;
    font-weight: 600;
  }
}

/*last section*/
.landingcontactsection {
  padding: 40px;
  background-color: #fff7e6;
}
.contactcard {
  padding: 18px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  font-size: 18px;
  font-weight: 800;
  color: rgb(255 169 0);
  background-color: white;
  border-radius: 20px;
  border-width: 0px;
  align-items: center;
}

.contactcardicons {
  height: 50px;
  width: 50px;
}
@media only screen and (max-width: 600px) {
  .landingcontactsection {
    padding: 16px;
    background-color: #fff7e6;
  }
  .contactcard {
    padding: 18px;
    display: flex;
    flex-direction: row;
    gap: 14px;
    font-size: 15px;
    font-weight: 800;
    color: rgb(255 169 0);
    background-color: white;
    border-radius: 10px;
    border-width: 0px;
    align-items: center;
  }
  .contactcardicons {
    height: 35px;
    width: 35px;
  }
}
