.footer {
  padding: 40px;
  background-color: rgb(255, 255, 255);
}
.footerlogo {
  height: 70px;
  width: 70px;
}
.footerlogocard {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #fe9300;
}
.footerdescription {
  font-size: 18px;
  color: rgb(53, 53, 53);
}
.footerallrightsreserved {
  font-size: 20px;
  font-weight: 600;
  color: black;
}
.footerheading {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 8px;
}
.footerheadingitems {
  font-size: 18px;
  font-weight: 500;
  color: rgb(100, 100, 100);
  margin-bottom: 8px;
}
@media only screen and (max-width: 600px) {
  .footer {
    padding: 16px;
    background-color: rgb(255, 255, 255);
  }
  .footerlogo {
    height: 30px;
    width: 30px;
  }
  .footerlogocard {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #fe9300;
  }
  .footerdescription {
    font-size: 15px;
    color: rgb(53, 53, 53);
  }
  .footerallrightsreserved {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
  .footerheading {
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin-bottom: 8px;
  }
  .footerheadingitems {
    font-size: 16px;
    font-weight: 500;
    color: rgb(100, 100, 100);
    margin-bottom: 8px;
  }
}
