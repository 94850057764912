.signuppageWrapper {
  padding: 40px;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  /* background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  background-image: url("../../../public/singupbg.jpg"); */
}
.loginWrapper {
  padding: 40px;
  background-color: #ffffff;
}
.singupcard {
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 32px;
  box-shadow: 1px 2px #f1a10a;
}
.singuptext {
  font-size: 42px;
  color: black;
  font-weight: 500;
}
.signupburgerimage {
  width: 100%;
  height: 100%;
}
.logincard {
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: rgb(246 149 70);
  border-radius: 32px;
  box-shadow: 3px 4px #000000;
}
.logintext {
  font-size: 42px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}
