.emailverifycontainer {
  padding: 40px;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}
.emailverifycard {
  width: 100%;
  max-width: 500px;
  padding: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailverificationtext {
  text-align: center;
  font-size: 24px;
  color: rgb(0 171 100);
}
