.foodcardcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  color: rgb(231, 46, 46);
  padding: 24px;
  background-color: rgb(255, 255, 255);
  border-color: aliceblue !important;
  border-radius: 16px;
  border-width: 0px;
}
.foodcardimage {
  /* width: 200px; */
  border-radius: 16px;
}
.foodcardheading {
  color: rgb(255, 145, 0);
}
.foodcarddesc {
  font-size: 16px;
  color: rgb(250, 191, 114);
}
.addbutton {
  padding: 8px;
  font-size: 16px;
  font-weight: 800;
  color: rgb(255 170 0);
  background-color: rgb(16 16 16);
  border-radius: 20px;
  border-width: 0px;
}
